import React from 'react'
import Loading from '../../components/Loading'

const Home: React.FC = () => {
  window.location.href = '/location/London'
  return (
    <Loading fullscreen/>
  )
}

export default Home
