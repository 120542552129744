import React, {
  useEffect, useRef, useState,
} from 'react'
import {
  Outlet, useLocation,
} from 'react-router-dom'
import { Box } from '@mui/material'
import {
  routes, type RouteProps, type RouteChildrenProps,
} from '../App'
import { stripQueryParameters } from '../utils/helpers'
import Header from './Header'

const Layout = () => {
  const [
    headerHeight,
    setHeaderHeight,
  ] = useState<string>('0px')
  const currentPath = useLocation().pathname
  const orderFlowRoutes: RouteChildrenProps[] | undefined = routes.find((route: RouteProps) => route.type === 'order-flow')?.children
  const currentStep: RouteChildrenProps | undefined = orderFlowRoutes?.find((route: RouteChildrenProps) =>
    currentPath.includes(stripQueryParameters(route.path)) && route.step)
  const headerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(`${headerRef.current.clientHeight}px`)
        document.documentElement.style.setProperty('--header-height', `${headerRef.current.clientHeight}px`)
      }
    }

    // Call the function initially
    updateHeaderHeight()

    // Add event listener for window resize
    window.addEventListener('resize', updateHeaderHeight)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeaderHeight)
    }
  }, [])

  return (
    <>
      <Header
        {...headerRef && { innerRef: headerRef }}
        routes={orderFlowRoutes}
        currentStep={currentStep}
      />
      <Box
        component="main"
        flexGrow="1"
        display="flex"
        flexDirection="column"
        pt={headerHeight}
        position="relative"
      >
        <Outlet/>
      </Box>
    </>
  )
}

export default Layout
