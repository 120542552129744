import React from 'react'
import { colors } from '../styles'

type LocationProps = {
  id?: string;
  absolute?: boolean;
  selected?: boolean;
  width?: number;
  height?: number;
}

const Pin = ({
  id,
  absolute,
  selected,
  width = 60,
  height = 80,
}: LocationProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    className="mapMarker"
    viewBox="0 0 60 80"
    {...absolute && {
      style: {
        position: 'absolute',
        bottom: '-12px',
        left: '50%',
        transform: 'translate(-50%, 0)',
        zIndex: 999,
      }
    }}
    {...id && {
      id: `pin-${id}`,
    }}
  >
    <g
      {...absolute && { filter: 'url(#a)' }}
    >
      <path
        fill={selected ? colors.blue[700] : colors.white[100]}
        d="M30.333 4C19.14 4 9 13.075 9 24.272 9 40.92 26.005 42.68 30.333 68c4.328-25.32 21.334-27.08 21.334-43.728C51.667 13.075 41.53 4 30.333 4Z"
        style={{
          transition: 'fill 0.2s'
        }}
        {...!absolute && !selected && {
          strokeWidth: '1',
          stroke: colors.neutral[400]
        }}
      />
    </g>
    <path
      fill={selected ? colors.white[100] : colors.blue[700]}
      fillRule="evenodd"
      d="M41.257 20.001c.406 0 .547.21.406.593l-5.48 14.9c-.143.39-.312.505-.737.505L30.728 36h-4.722c-.418 0-.517-.14-.385-.531l3.886-11.555.39-1.159.723-2.15c.143-.424.385-.604.839-.604h9.798Zm-22.74 0h9.271c.517 0 .646.182.483.67l-1.012 3.012c-1.124 3.348-2.249 6.695-3.38 10.04a.568.568 0 0 1-.155.205c-.029.028-.057.056-.082.085a1.365 1.365 0 0 0-.084-.08c-.062-.057-.124-.114-.154-.184a9804.28 9804.28 0 0 1-5.332-13.089c-.177-.436-.028-.659.445-.66Z"
      clipRule="evenodd"
      style={{
        transition: 'fill 0.2s'
      }}
    />
    <defs>
      <filter id="a" width="50.667" height="76" x="5" y="4" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="8"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix values="0 0 0 0 0.0862745 0 0 0 0 0.231373 0 0 0 0 0.427451 0 0 0 0.5 0"/>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1_19"/>
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1_19" result="shape"/>
      </filter>
    </defs>
  </svg>
)

export default Pin
