import React from 'react'
import {
  Alert, type AlertColor, Box, Icon, type IconProps, Button,
} from '@mui/material'
import { Link } from 'react-router-dom'
import {
  colors, fontSizes, hoverState,
} from '../styles'

type InfoBoxProps = {
  children?: React.ReactNode;
  mt?: number | Record<string, number>;
  mb?: number | Record<string, number>;
  icon?: IconProps['children'];
  iconPos?: 'center' | 'top' | 'bottom';
  severity?: AlertColor;
  action?: {
    to: string;
    text: string;
    icon?: React.ReactNode;
  };
}

const InfoBox = ({
  children, mt = 0, mb = 0, icon, iconPos = 'center', severity = 'info', action,
}: InfoBoxProps) => {
  const iconProps: IconProps = {
    children: icon,
    sx: {
      margin: 0.25
    }
  }
  const CustomIcon = () => (<Icon {...iconProps}/>)

  return (
    <Box
      component={Alert}
      severity={severity}
      mt={mt}
      mb={mb}
      alignItems={iconPos}
      sx={{
        '.MuiAlert-message': {
          fontSize: {
            xs: fontSizes.body.xs.fontSize,
            md: fontSizes.body.sm.fontSize,
          },
          lineHeight: {
            xs: fontSizes.body.xs.lineHeight,
            md: fontSizes.body.sm.lineHeight,
          }
        },
        '.MuiAlert-action': {
          marginRight: 0,
          paddingTop: 0
        },
      }}
      {...icon && { icon: <CustomIcon/> }}
      {...action && {
        action: (
          <Link to={action.to}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              className="pill"
              {...action.icon && {
                startIcon:
  <Icon
    sx={{
      height: '1em',
      width: '1em'
    }}
  >
    {action.icon}
  </Icon>
              }}
              sx={{
                '&[class*="MuiButtonBase"]': {
                  background: 'none',
                  borderColor: colors.blue[700],
                  color: colors.neutral[900],
                  '&:hover': hoverState,
                },
                '& .MuiButton-startIcon, & .MuiButton-startIcon>.MuiIcon-root, & .MuiSvgIcon-root': {
                  fontSize: 'inherit',
                },
                '& .MuiAlert-action': {
                  marginRight: 0,
                }
              }}
            >
              <span>{action.text}</span>
            </Button>
          </Link>
        )
      }}
    >
      {children}
    </Box>
  )
}

export default InfoBox
