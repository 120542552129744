import React from 'react'
import {
  Box, Button,
  Dialog, IconButton,
} from '@mui/material'
import {
  Close,
} from '@mui/icons-material'
import { colors } from '../styles'

export type ModalBoxProps = {
  open?: boolean;
  hasOKButton?: boolean;
  children: React.ReactNode;
  onClose?: () => void;
}

const ModalBox = ({
  open = false, onClose, children, hasOKButton = true,
}: ModalBoxProps) => {
  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <Box p={4}>
        {children}
      </Box>
      {hasOKButton &&
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <Button variant="contained" color="primary" onClick={handleClose} size="medium">
            OK
          </Button>
        </Box>}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: colors.blue[700]
        }}
        size="small"
      >
        <Close color="inherit"/>
      </IconButton>
    </Dialog>
  )
}

export default ModalBox
