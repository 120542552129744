import React, { useEffect } from 'react'
import { ThemeProvider } from '@mui/material'
import {
  Route, Routes, useLocation,
} from 'react-router-dom'
import TagManager from 'react-gtm-module'
import Location from './pages/OrderFlow/Location'
import Layout from './components/Layout'
import Home from './pages/Home/Home'
import PaymentDetails from './pages/OrderFlow/PaymentDetails'
import PersonalDetails from './pages/OrderFlow/PersonalDetails'
import OrderSummary from './pages/OrderFlow/OrderSummary'
import Quote from './pages/OrderFlow/Quote'
import Storage from './pages/OrderFlow/Storage'
import Booking from './pages/OrderFlow/Booking'
import { theme } from './styles'
import './App.css'

export type RouteProps = {
  path: string;
  element?: JSX.Element;
  type?: string;
  children?: RouteChildrenProps[];
}

export type RouteChildrenProps = {
  path: string;
  element: JSX.Element;
  title: string;
  step?: string;
}

export const orderFlowRoutes: RouteChildrenProps[] = [
  {
    path: '/',
    element: <Home/>,
    title: 'Home',
  },
  {
    path: '/location/:search_text?',
    element: <Location/>,
    title: 'Location',
    step: 'Find Location',
  },
  {
    path: '/storage',
    element: <Storage/>,
    title: 'Storage',
    step: 'Storage Size',
  },
  {
    path: '/quote',
    element: <Quote/>,
    title: 'Quote',
    step: 'Select Unit',
  },
  {
    path: '/details',
    element: <PersonalDetails/>,
    title: 'Personal Details',
    step: 'Your Details',
  },
  {
    path: '/summary',
    element: <OrderSummary/>,
    title: 'Summary',
    step: 'Reserve',
  },
  {
    path: '/payment',
    element: <PaymentDetails/>,
    title: 'Payment Details',
    step: 'Reserve',
  },
  {
    path: '/booking',
    element: <Booking/>,
    title: 'Booking',
    step: 'Reserve',
  },
]

export const routes: RouteProps[] = [{
  path: '/',
  element: <Layout/>,
  type: 'order-flow',
  children: orderFlowRoutes
}]

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const ScrollToTopOnRouterChange = () => {
  const location = useLocation()

  useEffect(() => {
    scrollToTop()
  }, [location])

  return null
}

const GoogleTagManager = () => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID!
    })
  }, [])
  return null
}

const Chat = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://clients.yomdel.com/tools/chat_script.js?url=' + document.location.href
    script.type = 'text/javascript'
    document.body.append(script)
  }, [])
  return null
}

const App = () => (
  <ThemeProvider theme={theme}>
    <ScrollToTopOnRouterChange/>
    <GoogleTagManager/>
    <Chat/>
    <Routes>
      {routes.map(route => {
        const {
          path, element, children,
        } = route
        return (
          <Route key={path} path={path} element={element}>
            { children?.map(child => {
              const {
                path, element,
              } = child
              return (
                <Route key={path} path={path} element={element}/>
              )
            })}
          </Route>
        )
      })}
    </Routes>
  </ThemeProvider>
)

export default App
