import React, {
  useState, useContext, useEffect
} from 'react'
import { Link } from 'react-router-dom'
import {
  Box, Button, Container, Hidden, IconButton, MenuItem, Paper, Select, Slider, Typography, useMediaQuery,
} from '@mui/material'
import {
  ArrowForward, ZoomOutMap, Add, Remove,
} from '@mui/icons-material'
import TagManager from 'react-gtm-module'
import {
  colors,
  contentHeight,
  fontSizes,
  mobileHeroHeight,
  theme,
} from '../../styles'
import BookingContext from '../../components/BookingContext'
import InfoBox from '../../components/InfoBox'
import {
  sizes,
} from '../../utils/data'
import RequestCallback from '../../components/RequestCallback'

const Storage = () => {
  const booking = useContext(BookingContext)

  const [
    selectedSpace,
    setSelectedSpace,
  ] = useState(booking.selectedSize)

  const [
    sliderValue,
    setSliderValue
  ] = useState<number>(0)

  const [
    contactFormVisible,
    setContactFormVisible,
  ] = useState(false)

  const handleSliderChange = (newValue: number | number[] | string) => {
    const parsedNewValue = Number(newValue)
    const newSliderValue = Array.isArray(newValue) ? newValue[0] : parsedNewValue
    const selectedSize = sizes.filter(
      size => booking.selectedLocation!.sizeOptions.includes(size.size)
    )[newSliderValue]
    booking.selectedSize = selectedSize
    setSelectedSpace(selectedSize)
    setSliderValue(newSliderValue)
  }

  const spaceDescription = selectedSpace.description

  useEffect(() => {
    const sliderIndex = sizes.filter(
      size => booking.selectedLocation!.sizeOptions.includes(size.size)
    )
      .findIndex(size => size.size === selectedSpace?.size)
    setSliderValue(sliderIndex)

    // Check if string has + in it
    if (selectedSpace.size.includes('+')) {
      setContactFormVisible(true)
    } else {
      setContactFormVisible(false)
    }
  }, [
    selectedSpace,
    booking.selectedLocation
  ])

  const marks = sizes.filter(
    size => booking.selectedLocation!.sizeOptions.includes(size.size)
  )
    .map((space, index) => ({
      value: index,
      label: `${space.size}`
    }))

  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  const handleDecrement = () => {
    if (sliderValue > 0) {
      handleSliderChange(sliderValue - 1)
    }
  }

  const handleIncrement = () => {
    if (sliderValue < marks.length - 1) {
      handleSliderChange(sliderValue + 1)
    }
  }

  const handleContinueClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'quote_size_selection',
        location: booking.selectedLocation?.name,
      }
    })
  }

  return (
    <>
      <Box
        alignItems="center"
        bgcolor={colors.neutral[50]}
        bottom={0}
        display="flex"
        justifyContent="center"
        height={{
          xs: mobileHeroHeight,
          md: contentHeight
        }}
        position={{
          xs: 'relative',
          md: 'fixed',
        }}
        right={0}
        width={{
          xs: '100vw',
          md: '50vw',
        }}
      >
        <img
          src={selectedSpace.image}
          height={isMd ? 'auto' : '100%'}
          width={isMd ? '70%' : 'auto'}
          alt={selectedSpace.description}
        />
      </Box>
      <Container
        sx={{
          flex: 1,
          m: 0,
          py: {
            xs: 4,
            sm: 5,
            md: 7
          },
          width: {
            xs: '100vw',
            md: '50vw',
          }
        }}
      >
        <Typography
          sx={{
            mb: {
              xs: 3,
              sm: 5,
              md: 6
            }
          }}
          variant="h1"
        >
          How much space do you need at <strong>{ booking.selectedLocation?.name }</strong>?
        </Typography>
        <Hidden smDown>
          <Typography
            color="primary"
            fontSize={fontSizes.title.fontSize}
            fontWeight={fontSizes.title.fontWeight}
            lineHeight={fontSizes.title.lineHeight}
            mb={{
              xs: 2,
              sm: 3,
              md: 5
            }}
            textAlign="center"
          >
            {selectedSpace.size} ft<sup>2</sup>
            {selectedSpace.size === '160' && <span> Container</span>}
          </Typography>
          <Slider
            aria-label="Restricted values"
            step={null}
            marks={marks}
            track={false}
            value={sliderValue}
            onChange={(_event, newValue) => {
              handleSliderChange(newValue)
            }}
            max={marks.length - 1}
            sx={{
              width: {
                xs: '90%',
                lg: '100%'
              },
              mb: 0,
              mx: 'auto',
              display: 'block',
            }}
          />
        </Hidden>
        <Hidden smUp>
          <Box display="flex" gap={0.5}>
            <IconButton
              size="large"
              className="square gold"
              onClick={handleDecrement}
              sx={{
                width: theme.spacing(6)
              }}
            >
              <Remove/>
            </IconButton>
            <Select
              value={sliderValue}
              sx={{
                flex: 1,
                '& [class*=MuiOutlinedInput]': {
                  borderColor: colors.gold[500],
                }
              }}
              onChange={event => {
                handleSliderChange(event.target.value)
              }}
            >
              {marks.map(mark => <MenuItem key={mark.value} value={mark.value}>{mark.label} sq ft {mark.label === '160' && <>Container</>}</MenuItem>)}
            </Select>
            <IconButton
              size="large"
              className="square gold"
              onClick={handleIncrement}
              sx={{
                width: theme.spacing(6)
              }}
            >
              <Add/>
            </IconButton>
          </Box>
        </Hidden>
        {contactFormVisible &&
          <Paper
            component={Box}
            gap={3}
            mt={{
              xs: 4,
              md: 8,
            }}
            p={4}
            variant="outlined"
          >
            <RequestCallback
              siteId={booking.selectedLocation?.siteId ?? ''}
              siteName={booking.selectedLocation?.name ?? ''}
              description={`Please leave your details and we will get back to you with a quote for a larger space at ${booking.selectedLocation?.name ?? 'unknown'}.`}
              reason={`Larger thank 150 sq. ft. size at ${booking.selectedLocation?.name ?? 'unknown'}`}
            />
          </Paper>}
        {spaceDescription && !contactFormVisible &&
          <InfoBox
            mt={{
              xs: 4,
              sm: 7,
              md: 8
            }}
            icon={<ZoomOutMap/>}
            iconPos="top"
          >
            {spaceDescription}
          </InfoBox>}
        {!contactFormVisible &&
          <Box
            mt={{
              xs: 3,
              sm: 4,
              md: 5,
            }}
            display="flex"
            width="100%"
          >
            <Link to="/quote" style={{ width: '100%' }}>
              <Button variant="contained" fullWidth color="primary" size="large" endIcon={<ArrowForward/>} onClick={handleContinueClick}>
                Continue
              </Button>
            </Link>
          </Box>}
      </Container>
    </>
  )
}

export default Storage
