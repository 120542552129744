import React, {
  useState, useEffect, useContext, type ChangeEvent, type MouseEvent
} from 'react'
import {
  useParams, useSearchParams, useNavigate, Link
} from 'react-router-dom'
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  Add,
  ArrowForward,
  Close,
  Email,
  Phone,
  Search,
} from '@mui/icons-material'
import debounce from 'lodash.debounce'
import TagManager from 'react-gtm-module'
import BookingContext from '../../components/BookingContext'
import MapBox from '../../components/Map'
import Pin from '../../components/Pin'
import {
  callSMProxyApiFindSites,
  fetchLocationAutocompleteSuggestions,
  type SMProxyFindSitesDataProps,
  type SMProxyResponseProps
} from '../../utils/apis'
import {
  type LocationProps,
  defaultSize, defaultMapGPSCoordinates, type GPSCoordinates, type AutocompleteOptionProps, type BookingContextProps
} from '../../utils/data'
import Loading from '../../components/Loading'
import InfoBox from '../../components/InfoBox'
import {
  colors, contentHeight, theme,
} from '../../styles'
import { textWithIcon } from '../../utils/commonStyling'

function setBookingLocation(
  booking: BookingContextProps,
  data: {
    location: LocationProps;
    locations: LocationProps[];
    searchValue: AutocompleteOptionProps | undefined;
    mapCenter: GPSCoordinates;
  }
) {
  booking.selectedLocation = data.location
  booking.foundLocations = data.locations
  booking.selectedSize = defaultSize
  booking.searchText = data.searchValue?.label ?? undefined
  booking.searchTextGPSCoordinates = data.mapCenter
  booking.confirmedLocation = undefined
  booking.confirmedSize = undefined
  booking.firstName = undefined
  booking.lastName = undefined
  booking.email = undefined
  booking.phone = undefined
  booking.address = {
    id: undefined,
    address: undefined,
    town: undefined,
    county: undefined,
    postcode: undefined,
    fullAddress: undefined
  }
  booking.startDate = undefined
  booking.duration = undefined
  booking.stripeRef = undefined
  booking.bookingRef = undefined
}

const Location = () => {
  const booking = useContext(BookingContext)

  const { search_text } = useParams()
  const navigate = useNavigate()
  const [searchParameters] = useSearchParams()
  const selectFirst = searchParameters.get('selectFirst') === '1'

  const [
    loading,
    setLoading,
  ] = useState(false)

  const [
    activePin,
    setActivePin,
  ] = useState<LocationProps>()

  const [
    locations,
    setLocations
  ] = useState<LocationProps[]>([])

  const [
    mapCenter,
    setMapCenter
  ] = useState<GPSCoordinates>(defaultMapGPSCoordinates)

  const [
    mapZoomLevel,
    setMapZoomLevel
  ] = useState<number>(10)

  const [
    error,
    setError,
  ] = useState<string | undefined>()

  const [
    info,
    setInfo,
  ] = useState<string | undefined>()

  const [
    searchValue,
    setSearchValue,
  ] = useState<AutocompleteOptionProps | undefined>({
    id: '',
    label: booking.searchText ?? search_text ?? 'London',
  } as AutocompleteOptionProps)

  const [
    searchOptions,
    setSearchOptions
  ] = useState<AutocompleteOptionProps[]>([])

  const toggleLocation = (item: LocationProps) => {
    setActivePin(item)
    setMapCenter(item?.coordinates)
    setBookingLocation(
      booking,
      {
        location: item,
        locations,
        searchValue,
        mapCenter
      }
    )
  }

  const handleValue = (value: LocationProps) => {
    setActivePin(value)
  }

  const handleSearchChange = (_event: ChangeEvent<HTMLInputElement>, option: AutocompleteOptionProps) => {
    setSearchValue(option)
  }

  const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation()

    // Send phone_call_clicks event to Google Tag Manager if the link starts with tel:
    if (event.currentTarget.href.startsWith('tel:')) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'booking_phone_call_click',
          location: activePin?.name,
        }
      })
    } else if (event.currentTarget.href.startsWith('mailto:')) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'booking_email_click',
          location: activePin?.name,
        }
      })
    }

    window.location.href = event.currentTarget.href
    event.preventDefault()
  }

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event?.target?.value
    if (inputValue) {
      fetchLocationAutocompleteSuggestions(inputValue)
        .then((options: AutocompleteOptionProps[]) => {
          setSearchOptions(options)
        })
        .catch(() => {
          console.log('Error fetching address autocomplete suggestions')
        })
    } else {
      setSearchOptions([])
    }
  }

  const handleSelectLocationClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'quote_store_selection',
        location: activePin?.name
      }
    })
  }

  useEffect(() => {
    if (!searchValue || searchValue.label?.length === 0) {
      setLocations([])
      return
    }

    setLoading(true)

    callSMProxyApiFindSites(searchValue.label)
      .then((response: SMProxyResponseProps<SMProxyFindSitesDataProps>) => {
        if (response.error) {
          setError(`Failed to find site at "${searchValue.label}". Please try again.`)
          console.error(response.error)
          setLoading(false)
          return
        }

        if (
          !response.data ||
          response.data.location_coordinates === undefined ||
          response.data.sites === undefined
        ) {
          setError(`Failed to find site at "${searchValue.label}" due to technical difficulties. Please try again later.`)
          console.error('Invalid response from server. Missing GPS coordinates or sites')
          setLoading(false)
          return
        }

        setError(undefined)
        setLoading(false)
        setMapCenter(response.data.location_coordinates)

        const foundLocations: LocationProps[] = []
        const allLocations: LocationProps[] = []
        for (const site of response.data.sites) {
          const distance = Math.round(site.distance_miles)
          let distance_text = distance.toFixed(0) + ' miles away'
          if (distance === 1) {
            distance_text = '1 mile away'
          }

          const newLocation: LocationProps = {
            id: site.SiteID,
            siteId: site.site_id,
            name: site.DisplayName,
            address: [
              site.Address2,
              site.Address3,
              site.County,
              site.Town,
              site.PostCode
            ]
              .filter(text => text !== null)
              .join(', '),
            distance: distance_text,
            coordinates: site.GPSCoordinates,
            manAndVan: site.ManAndVan,
            contacts: {
              email: site.Email,
              phone: site.Telephone,
            },
            image: `/images/storage-location-${site.site_id}.jpg`,
            sizes: site.available_sizes,
            sizeOptions: site.SizeOptions,
            weeksInAdvanceBooking: site.WeeksInAdvanceBooking,
            insuranceStartingPrice: site.InsuranceStartingPrice,
            padlockRequired: site.PadlockRequired,
          }

          if (site.found) {
            foundLocations.push(newLocation)
          }

          allLocations.push(newLocation)
        }

        if (foundLocations.length === 0) {
          setInfo(`We are not at "${searchValue.label}", but we are at these locations:`)
          setLocations(allLocations)
          setMapZoomLevel(6)
        } else {
          setInfo('')
          setLocations(foundLocations)
          setMapZoomLevel(10)
        }

        setActivePin(undefined)

        // If there is a selectNext prop passed to the URL, then select the first locatiuon in the found list
        // and redirect to the storage page
        if (selectFirst && search_text && foundLocations.length > 0) {
          setBookingLocation(
            booking,
            {
              location: foundLocations[0],
              locations: foundLocations,
              searchValue,
              mapCenter: response.data.location_coordinates
            }
          )
          navigate('/storage')
        }

        setLoading(false)
      })
      .catch(error => {
        setLocations([])
        setError(`Failed to find site at "${searchValue.label}" due to technical difficulties. Please try again later.`)
        console.error('Invalid response from server. Missing GPS coordinates or sites', error)
        setLoading(false)
      })
  }, [
    searchValue,
    selectFirst,
    search_text,
    booking,
    navigate
  ])

  return (
    <>
      {selectFirst && <Loading fullscreen/>}
      <Box
        display="flex"
        flexDirection={{
          xs: 'column-reverse',
          md: 'row'
        }}
      >
        <Box
          width={{
            xs: '100%',
            md: '40%',
            lg: '30%',
          }}
          pt={{
            xs: 4,
            lg: 7,
          }}
        >
          <Stack
            px={{
              xs: 3,
              lg: 4,
            }}
            spacing={4}
            mb={2}
          >
            <Typography variant="h1">Find your self storage location</Typography>
            <Autocomplete
              options={searchOptions}
              getOptionLabel={(option: AutocompleteOptionProps) => option.label ?? ''}
              popupIcon={<Search color="primary"/>}
              sx={{
                '& .MuiAutocomplete-popupIndicator': { transform: 'none' },
              }}
              onChange={handleSearchChange}
              onInputChange={debounce((value: ChangeEvent<HTMLInputElement>) => {
                handleSearchInputChange(value)
              }, 500)}
              value={searchValue}
              filterOptions={x => x}
              noOptionsText="No location found"
              renderInput={parameters => (
                <TextField
                  {...parameters}
                  label="Enter Postcode or Town"
                  variant="outlined"
                />
              )}
            />
            {error && !loading && <InfoBox severity="warning">{ error }</InfoBox>}
            {info && !loading && <InfoBox severity="info">{ info }</InfoBox>}
            {loading && <Loading/>}
          </Stack>
          {!loading && locations && locations.length > 0 && !error &&
            <List
              sx={{
                flexGrow: '1',
                overflow: 'auto',
              }}
            >
              {locations.map((location: LocationProps, index: number) => {
                const selected = activePin?.id === location.id
                return (
                  <div key={location.id}>
                    {index !== 0 && <Divider/>}
                    <Box
                      sx={{
                        cursor: 'pointer',
                        px: {
                          xs: 3,
                          lg: 4,
                        },
                        py: 2,
                        ...selected && {
                          background: colors.neutral[50],
                          boxShadow: `-4px 0 0 ${colors.gold[400]} inset`
                        },
                        '&:hover': {
                          background: colors.neutral[50],
                        }
                      }}
                      onClick={() => {
                        if (!selected) {
                          toggleLocation(location)
                        }
                      }}
                    >
                      <Box display="flex" gap={1}>
                        <Box left="-6px" top="-2px" position="relative">
                          <Pin
                            selected={selected}
                            width={46}
                            height={60}
                          />
                        </Box>
                        <Box flexGrow={1}>
                          <Typography variant="h5" sx={{ mb: 1 }}>
                            {location.name}
                          </Typography>
                          <Typography variant="caption" component="div" sx={{ mb: 1 }}>
                            {location.address}
                          </Typography>
                          <Typography variant="caption" component="div" color={colors.neutral[600]}>
                            {location.distance}
                          </Typography>
                          {selected && location?.contacts &&
                            <Stack mt={2} spacing={1} width="min-content">
                              {location.contacts?.email &&
                                <Link
                                  to={`mailto:${location.contacts?.email}`}
                                  onClick={handleLinkClick}
                                  style={textWithIcon}
                                >
                                  <Email color="secondary" sx={{ fontSize: '1em' }}/>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      wordWrap: 'break-word',
                                    }}
                                    fontWeight={700}
                                  >
                                    <div>{location.contacts?.email}</div>
                                  </Typography>
                                </Link>}
                              {location.contacts?.phone &&
                                <Link
                                  to={`tel:${location.contacts?.phone}`}
                                  onClick={handleLinkClick}
                                  style={textWithIcon}
                                >
                                  <Phone color="secondary" sx={{ fontSize: '1em' }}/>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      wordWrap: 'break-word',
                                    }}
                                    fontWeight={700}
                                  >
                                    {location.contacts?.phone}
                                  </Typography>
                                </Link>}
                            </Stack>}
                        </Box>
                        <div>
                          <IconButton
                            size="small"
                            className="square"
                            {...selected && {
                              color: 'primary',
                            }}
                            onClick={() => {
                              if (selected) {
                                setActivePin(undefined)
                              }
                            }}
                          >
                            {selected ?
                              <Close/> :
                              <Add/>}
                          </IconButton>
                        </div>
                      </Box>
                      {selected &&
                        <Link
                          to="/storage"
                          style={{
                            display: 'block',
                            marginTop: theme.spacing(3),
                            marginBottom: theme.spacing(1),
                          }}
                        >
                          <Button variant="contained" color="primary" endIcon={<ArrowForward/>} onClick={handleSelectLocationClick} disableElevation fullWidth>
                            Select location
                          </Button>
                        </Link>}
                    </Box>
                  </div>
                )
              })}
            </List>}
        </Box>
        <div>
          <MapBox
            center={mapCenter}
            zoom={mapZoomLevel}
            markers={locations}
            activePin={activePin}
            onEmit={handleValue}
            height={{
              xs: '30vh',
              md: contentHeight,
            }}
            width={{
              xs: '100%',
              md: '60%',
              lg: '70%',
            }}
            position={{
              xs: 'relative',
              md: 'fixed',
            }}
            controls
          />
        </div>
      </Box>
    </>
  )
}

export default Location
