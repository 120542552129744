import React, {
  useRef, useEffect, useState
} from 'react'
import {
  renderToStaticMarkup,
} from 'react-dom/server'
import mapboxgl, { type Map } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Box } from '@mui/material'
import {
  colors,
  palette,
} from '../styles'
import {
  type GPSCoordinates, type LocationProps, type PositionType
} from '../utils/data'
import Pin from './Pin'

type ResponsiveProps = Record<string, string>

type MapProps = {
  zoom: number;
  center: GPSCoordinates;
  width?: string | ResponsiveProps;
  height?: string | ResponsiveProps;
  position?: PositionType | PositionWithSizesType;
  markers?: LocationProps[];
  activePin?: LocationProps;
  controls?: boolean;
  centerPin?: LocationProps | undefined;
  onEmit?: (marker: LocationProps) => void;
  offset?: number;
}

type PositionWithSizesType = {
  xs: PositionType;
  md: PositionType;
}

const MapBox = ({
  center, zoom, width, height, position = 'relative', markers, activePin, onEmit, controls, centerPin, offset,
}: MapProps) => {
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const [
    map,
    setMap,
  ] = useState<Map | undefined>(undefined)

  const changePinColor = (pin: Element, color: string, logoColor: string) => {
    const logo = pin.children[1] as HTMLElement
    const shape = pin.querySelector('g')?.children[0] as HTMLElement
    if (logo) {
      logo.style.fill = logoColor
    }

    if (shape) {
      shape.style.fill = color
    }
  }

  const addPinToMap = (map: Map, marker: LocationProps, emit?: boolean) => {
    const markerElement = document.createElement('div')
    markerElement.innerHTML = renderToStaticMarkup(<Pin id={marker.id} absolute/>)
    const pin = new mapboxgl.Marker(markerElement)
    pin.setLngLat(marker.coordinates)
      .addTo(map)
    if (emit) {
      pin.getElement()
        .addEventListener('click', () => {
          if (onEmit) {
            onEmit(marker)
          }
        })
    }
  }

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY!

    if (mapContainerRef.current) {
      if (map) {
        map.remove()
      }

      const newMap = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: process.env.REACT_APP_MAPBOX_STYLE!,
        center,
        zoom,
        attributionControl: false
      })

      setMap(newMap)

      const navigationControl = new mapboxgl.NavigationControl()
      if (controls) {
        newMap.addControl(navigationControl, 'top-right')
      }

      if (offset) {
        const currentCenter = newMap.getCenter()
        const newCenter = new mapboxgl.LngLat(
          currentCenter.lng,
          currentCenter.lat + offset,
        )
        newMap.setCenter(newCenter)
      }

      if (centerPin) {
        addPinToMap(newMap, centerPin)
      }

      if (markers) {
        for (const marker of markers) {
          addPinToMap(newMap, marker, true)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    markers,
    center
  ])

  useEffect(() => {
    if (activePin && (activePin === centerPin)) {
      const markers = document.querySelectorAll('.mapMarker')
      if (markers) {
        for (const marker of markers) {
          changePinColor(marker, colors.white[100], palette.primary.main)
        }
      }

      const activePinId = document.querySelector(`#pin-${activePin.id}`)
      if (activePinId) {
        changePinColor(activePinId, palette.secondary.main, colors.white[100])
      }
    }
  }, [
    map,
    activePin,
    centerPin,
  ])

  useEffect(() => {
    if (markers) {
      const pins = document.querySelector('.mapboxgl-canvas-container')?.children

      if (pins) {
        for (const pin of pins) {
          if (pin.children[0]) {
            changePinColor(pin.children[0] as HTMLElement, colors.white[100], palette.primary.main)
          }
        }
      }
    }

    if (activePin && (activePin !== centerPin)) {
      const activePinId = document.querySelector(`#pin-${activePin.id}`)
      if (activePinId) {
        changePinColor(activePinId, palette.primary.main, colors.white[100])
      }
    }
  }, [
    centerPin,
    activePin,
    markers,
  ])

  return (
    <Box
      bgcolor={colors.neutral[50]}
      width={width}
      height={height}
      overflow="hidden"
      className="map-container"
      ref={mapContainerRef}
      position={position}
    />
  )
}

export default MapBox
