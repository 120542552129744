import React from 'react'
import {
  Box,
  CircularProgress, Typography
} from '@mui/material'
import { colors } from '../styles'

const Loading = ({
  fullscreen
}: {
  fullscreen?: boolean;
}) => (
  <Box
    bgcolor={colors.white[100]}
    height="100%"
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    p={2}
    boxSizing="border-box"
    {...fullscreen && {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 100,
    }}
  >
    <CircularProgress
      size={48}
      color="primary"
      thickness={2}
      sx={{
        marginBottom: 3
      }}
    />
    <Typography variant="caption" color="primary">
      Please wait...
    </Typography>
  </Box>
)

export default Loading
