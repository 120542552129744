import {
  Phone,
  Check
} from '@mui/icons-material'
import {
  type AlertColor,
  Box, Button, Checkbox, FormControlLabel, FormGroup, TextField, Typography, Link
} from '@mui/material'
import React, {
  type ChangeEvent, type MouseEvent, useEffect, useState, useRef
} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import TagManager from 'react-gtm-module'
import {
  isValidEmailAddress, isValidPhoneNumber
} from '../utils/validate'
import {
  callSMProxyApiRequestCallback,
  type SMProxyRequestCallbackResponseProps,
  type SMProxyResponseProps
} from '../utils/apis'
import ModalBox from './ModalBox'
import IconWithText from './IconWithText'
import Loading from './Loading'
import InfoBox from './InfoBox'

type RequestCallbackProps = {
  siteId: string;
  siteName: string;
  description: string;
  reason: string;
}

const RequestCallback = (
  {
    siteId,
    siteName,
    description = 'Please provide your details below and we will call you back as soon as possible.',
    reason = 'Callback request'
  }: RequestCallbackProps
) => {
  const reCaptchaRef = useRef<ReCAPTCHA>(null)

  const [
    state,
    setState,
  ] = useState({
    fullName: '',
    email: '',
    phone: '',
    agreeToTerms: false,
    agreeToPrivacyPolicy: false
  })

  const [
    loading,
    setLoading,
  ] = useState(false)

  const [
    showSuccess,
    setShowSuccess,
  ] = useState(false)

  const [
    errorState,
    setErrorState,
  ] = useState({
    fullName: undefined as string | undefined,
    email: undefined as string | undefined,
    phone: undefined as string | undefined
  })

  const [
    modalMessage,
    setModalMessage
  ] = useState({
    visible: false,
    title: 'Modal Message Title',
    text: 'Modal message text',
    severity: 'error' as AlertColor
  })

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'agreeToTerms' || event.target.name === 'agreeToPrivacyPolicy') {
      type StateProperties = 'agreeToTerms' | 'agreeToPrivacyPolicy'
      const property = event.target.name as StateProperties
      setState(previousState => ({
        ...previousState,
        [property]: event.target.checked
      }))
    } else {
      type StateProperties = 'name' | 'email' | 'phone'
      const property = event.target.name as StateProperties
      setState(previousState => ({
        ...previousState,
        [property]: event.target.value
      }))
    }
  }

  useEffect(() => {
    // Perform for validation
    const newErrorState = {
      fullName: undefined as string | undefined,
      email: undefined as string | undefined,
      phone: undefined as string | undefined,
    }

    if (
      state.fullName !== undefined &&
      state.fullName.length > 0 &&
      state.fullName.length < 5
    ) {
      newErrorState.fullName = 'You should provide a name with at least 5 characters in length'
    }

    if (state.email !== undefined && state.email.length > 0 && !isValidEmailAddress(state.email)) {
      newErrorState.email = 'Please enter a valid email address'
    }

    if (state.phone !== undefined && state.phone.length > 0 && !isValidPhoneNumber(state.phone)) {
      newErrorState.phone = 'Please enter a valid phone number e.g. 07123123123'
    }

    setErrorState(newErrorState)
  }, [state])

  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    // Check if there is some error state to take care of
    type ErrorStateProperties = 'fullName' | 'email' | 'phone'
    for (const propertyName of Object.keys(errorState)) {
      if (Object.prototype.hasOwnProperty.call(errorState, propertyName)) {
        const property = propertyName as ErrorStateProperties

        if (errorState[property] !== undefined) {
          setModalMessage({
            visible: true,
            title: 'Oops! There are a few things to fix.',
            text: 'Hey there! We\'ve got some form hiccups. 😅 Please check and fix errors before moving forward. Need help? Just ask. Thanks!',
            severity: 'error' as AlertColor
          })
          return
        }

        if (state[property] === undefined || (state[property]?.length === 0)) {
          setModalMessage({
            visible: true,
            title: 'Required Fields Missing',
            text: 'Oops! Please fill in all required fields marked with (*) in the form before submitting. Thanks!',
            severity: 'error' as AlertColor
          })
          return
        }
      }
    }

    // Check terms & Privacy Policy clicks <
    if (!state.agreeToTerms || !state.agreeToPrivacyPolicy) {
      setModalMessage({
        visible: true,
        title: 'Agreement Required',
        text: 'Please agree to our Terms and Conditions and Privacy Policy before moving forward',
        severity: 'warning' as AlertColor
      })
      return
    }

    if (reCaptchaRef.current === null) {
      setModalMessage({
        visible: true,
        title: 'Recaptcha Failed',
        text: 'We could not verify that you are not a robot. Please try again later.',
        severity: 'error' as AlertColor
      })
      return
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'quote_callback_request',
        location: siteName,
      }
    })

    // First do the recaptcha before sending the request to the server
    reCaptchaRef.current.executeAsync()
      .then(token => {
        setLoading(true)

        // Send the callback request
        callSMProxyApiRequestCallback({
          site_id: siteId,
          name: state.fullName,
          email: state.email,
          phone: state.phone,
          token: token ?? undefined,
          reason
        })
          .then((response: SMProxyResponseProps<SMProxyRequestCallbackResponseProps>) => {
            if (response.error ?? !response.data?.success) {
              if (response.error) {
                console.error(response.error)
              } else {
                console.error(response.data?.message)
              }

              setModalMessage({
                visible: true,
                title: 'Callback Request Failed',
                text: 'Failed to request callback due to technical difficulties. Please try again later.',
                severity: 'error' as AlertColor
              })
              setLoading(false)

              return
            }

            // Set an empty state
            setState({
              fullName: '',
              email: '',
              phone: '',
              agreeToPrivacyPolicy: false,
              agreeToTerms: false
            })

            setShowSuccess(true)
            setLoading(false)
          })
          .catch(error => {
            console.error(error)
            setModalMessage({
              visible: true,
              title: 'Callback Request Failed',
              text: 'Failed to request callback due to technical difficulties. Please try again later.',
              severity: 'error' as AlertColor
            })
            setLoading(false)
          })

        // Reset the reCAPTCHA for subsequent use
        reCaptchaRef.current?.reset()
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        setModalMessage({
          visible: true,
          title: 'Recaptcha Failed',
          text: 'We could not verify that you are not a robot. Please try again later.',
          severity: 'error' as AlertColor
        })
      })
  }

  return (
    <>
      {loading && <Loading/>}
      {!loading && showSuccess &&
        <IconWithText
          title="Callback Requested"
          text="Thank you for your call back request. A member of the team will call you back as soon as possible."
          icon={<Check/>}
          severity="success"
        />}
      {!loading && !showSuccess &&
        <>
          <Box mb={3}>
            <Box display="flex" alignItems="center" mb={2}>
              <Phone
                color="secondary"
                sx={{
                  marginRight: 1
                }}
              />
              <Typography variant="h4">
                Request a callback
              </Typography>
            </Box>
            <Typography variant="body2" color="gray" gutterBottom>
              {description}{' '}
              We will not add you to any mailing lists or share your details with third parties.
            </Typography>
          </Box>
          <Box
            display="flex"
            gap={1.5}
            flexDirection="column"
            width="100%"
          >
            <TextField
              id="fullName"
              name="fullName"
              label="Full name"
              type="text"
              size="small"
              value={state.fullName}
              onChange={handleInputChange}
              error={errorState.fullName !== undefined}
              helperText={errorState.fullName}
              fullWidth
              required
            />
            <TextField
              id="phone"
              name="phone"
              label="Phone number"
              type="tel"
              size="small"
              value={state.phone}
              onChange={handleInputChange}
              error={errorState.phone !== undefined}
              helperText={errorState.phone}
              fullWidth
              required
            />
            <TextField
              id="emailAddress"
              name="email"
              label="Email address"
              type="email"
              size="small"
              value={state.email}
              onChange={handleInputChange}
              error={errorState.email !== undefined}
              helperText={errorState.email}
              fullWidth
              required
            />
            <FormGroup
              sx={{
                gap: 1,
                py: 1
              }}
            >
              <FormControlLabel
                required
                control={
                  <Checkbox
                    name="agreeToTerms"
                    checked={state.agreeToTerms}
                    onChange={handleInputChange}
                  />
                }
                label="I agree to the Terms and Conditions of this service"
              />
              <FormControlLabel
                required
                control={
                  <Checkbox
                    name="agreeToPrivacyPolicy"
                    checked={state.agreeToPrivacyPolicy}
                    onChange={handleInputChange}
                  />
                }
                label="I agree to the Privacy Policy and cookies policy of this site"
              />
            </FormGroup>
            <InfoBox iconPos="top" mt={0} mb={1}>
              Please check our{' '}
              <Link href="https://www.vanguardstorage.co.uk/self-storage-terms-conditions/" target="_blank">
                Terms and Conditions
              </Link>
              {' '}and{' '}
              <Link href="https://www.vanguardstorage.co.uk/privacy-policy/" target="_blank">
                Privacy Policy
              </Link>
            </InfoBox>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={handleSubmit}
            >
              Call me back
            </Button>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY!}
              size="invisible"
              ref={reCaptchaRef}
            />
          </Box>
        </>}
      <ModalBox
        open={modalMessage.visible}
        onClose={() => {
          setModalMessage(previousState => ({
            ...previousState,
            visible: false
          }))
        }}
      >
        <IconWithText
          title={modalMessage.title}
          text={modalMessage.text}
          severity={modalMessage.severity}
        />
      </ModalBox>
    </>
  )
}

export default RequestCallback
