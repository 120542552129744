import React from 'react'
import {
  Avatar,
  Box, type SvgIconProps, Typography, type AlertColor,
} from '@mui/material'
import {
  PriorityHigh,
} from '@mui/icons-material'
import { palette } from '../styles'

export type IconWithTextProps = {
  icon?: React.ReactElement<SvgIconProps>;
  severity?: AlertColor | undefined;
  title?: string;
  text: string;
}

const IconWithText = ({
  icon = <PriorityHigh/>, title, text, severity,
}: IconWithTextProps) => {
  const setIconColor = (color: IconWithTextProps['severity']) => {
    switch (color) {
      case 'error': {
        return palette.error.main
      }

      case 'success': {
        return palette.success.main
      }

      default: {
        return palette.secondary.main
      }
    }
  }

  return (
    <Box
      textAlign="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {icon &&
        <Avatar
          sx={{
            mb: 2,
            backgroundColor: setIconColor(severity),
          }}
        >
          {icon}
        </Avatar>}
      {title && <Typography variant="h4" gutterBottom>{title}</Typography>}
      {text && <Typography variant="body1">{text}</Typography>}
    </Box>
  )
}

export default IconWithText
