import React, {
  useState, useContext, type ChangeEvent, type MouseEvent, useEffect
} from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
  Autocomplete, type AlertColor,
  Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup,
  Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography, styled, Stack,
} from '@mui/material'
import {
  Search, CalendarMonth, ArrowForward
} from '@mui/icons-material'
import {
  useNavigate, Link
} from 'react-router-dom'
import dayjs, { type Dayjs } from 'dayjs'
import debounce from 'lodash.debounce'
import TagManager from 'react-gtm-module'
import {
  colors, palette,
} from '../../styles'
import {
  type BookingContextProps,
  type DurationType,
  type AddressProps,
  type AutocompleteOptionProps,
  type CountryCodeType,
  defaultCountryCode,
  TitleType,
  countryCodes,
  DurationTypeTitles
} from '../../utils/data'
import {
  fetchAddressAutocompleteSuggestions, fetchAddressDetails
} from '../../utils/apis'
import BookingContext from '../../components/BookingContext'
import ModalBox from '../../components/ModalBox'
import IconWithText from '../../components/IconWithText'
import {
  isValidEmailAddress, isValidPhoneNumber
} from '../../utils/validate'
import InfoBox from '../../components/InfoBox'
import {
  BOX, CONTAINER, CTA, FORM
} from '../../utils/commonStyling'
import UnitLarge from '../../components/UnitLarge'
import RequestCallback from '../../components/RequestCallback'

const OpenPickerIcon = styled(CalendarMonth)({
  color: palette.primary.main,
})

const PersonalDetails = () => {
  const booking: BookingContextProps = useContext(BookingContext)

  const navigate = useNavigate()

  const [
    state,
    setState,
  ] = useState({
    title: booking.title,
    firstName: booking.firstName,
    lastName: booking.lastName,
    email: booking.email,
    phoneCountryCode: booking.phoneCountryCode,
    phone: booking.phone,
    startDate: booking.startDate,
    duration: booking.duration,
    agreeToTerms: false,
    agreeToPrivacyPolicy: false
  })

  const [
    errorState,
    setErrorState,
  ] = useState({
    firstName: undefined as string | undefined,
    lastName: undefined as string | undefined,
    email: undefined as string | undefined,
    phone: undefined as string | undefined
  })

  const [
    currentAddress,
    setCurrentAddress,
  ] = useState<AutocompleteOptionProps>({
    id: booking.address.id ?? '',
    label: booking.address.fullAddress ?? ''
  })

  const [
    addressOptions,
    setAddressOptions
  ] = useState<AutocompleteOptionProps[]>([])

  const [
    modalMessage,
    setModalMessage
  ] = useState({
    visible: false,
    title: 'Modal Message Title',
    text: 'Modal message text',
    severity: 'error' as AlertColor
  })

  const [
    requestCallbackVisible,
    setRequestCallbackVisible
  ] = useState(false)

  const toggleRequestCallBackVisibility = () => {
    setRequestCallbackVisible(!requestCallbackVisible)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'agreeToTerms' || event.target.name === 'agreeToPrivacyPolicy') {
      type StateProperties = 'agreeToTerms' | 'agreeToPrivacyPolicy'
      const property = event.target.name as StateProperties
      setState(previousState => ({
        ...previousState,
        [property]: event.target.checked
      }))
    } else {
      type StateProperties = 'firstName' | 'lastName' | 'email' | 'phone'
      const property = event.target.name as StateProperties
      setState(previousState => ({
        ...previousState,
        [property]: event.target.value
      }))
      booking[property] = event.target.value
    }
  }

  const handleAddressInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event?.target?.value
    if (inputValue) {
      fetchAddressAutocompleteSuggestions(inputValue)
        .then((options: AutocompleteOptionProps[]) => {
          setAddressOptions(options)
        })
        .catch(() => {
          console.log('Error fetching address autocomplete suggestions')
        })
    } else {
      setAddressOptions([])
    }
  }

  const handleAddressChange = (_event: ChangeEvent<HTMLInputElement>, address_option: AutocompleteOptionProps) => {
    fetchAddressDetails(address_option.id)
      .then((address: AddressProps) => {
        if (
          address === null ||
        address.address === undefined || address.address.length === 0 ||
        address.postcode === undefined || address.postcode.length === 0
        ) {
          setModalMessage({
            visible: true,
            title: 'Share Your Full Address',
            text: 'In order to book your self-storage unit we will need your full address with a postcode. ' +
            'Please select your address from the list of suggestions.',
            severity: 'error' as AlertColor
          })
        } else {
          setCurrentAddress(address_option)
          booking.address = address
        }
      })
      .catch(() => {
        setModalMessage({
          visible: true,
          title: 'Address Not Found',
          text: 'Please select your address from the list of suggestions.',
          severity: 'error' as AlertColor
        })
      })
  }

  const handleDurationChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: DurationType = event.target.value as unknown as DurationType
    setState(previousState => ({
      ...previousState,
      duration: value
    }))
    booking.duration = value
  }

  const handlePhoneCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const newCountryCode = countryCodes.find(code => code.code === value)
    setState(previousState => ({
      ...previousState,
      phoneCountryCode: newCountryCode ?? defaultCountryCode
    }))
    booking.phoneCountryCode = newCountryCode ?? defaultCountryCode
  }

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: TitleType = event.target.value as unknown as TitleType
    setState(previousState => ({
      ...previousState,
      title: value
    }))
    booking.title = value
  }

  const handleStartDateChange = (event: Dayjs | { $d: Date }) => {
    if ((event === null) || !('$d' in event)) {
      return
    }

    // Check if event date is more than 2 weeks ahead from today and if so - show the request callback modal
    // Otherwise change the date
    const twoWeeksAhead = dayjs()
      .add(booking.confirmedLocation?.weeksInAdvanceBooking ?? 4, 'week')
    if (dayjs(event.$d) > twoWeeksAhead) {
      setState(previousState => ({
        ...previousState,
        startDate: undefined
      }))
      booking.startDate = undefined
      TagManager.dataLayer({
        dataLayer: {
          event: 'quote_too_far_start_date_select',
          location: booking.confirmedLocation?.name
        }
      })
      toggleRequestCallBackVisibility()
    } else {
      setState(previousState => ({
        ...previousState,
        startDate: event.$d
      }))
      booking.startDate = event.$d
    }
  }

  const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation()

    // Send phone_call_clicks event to Google Tag Manager if the link starts with tel:
    if (event.currentTarget.href.startsWith('tel:')) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'booking_phone_call_click',
          location: booking.confirmedLocation!.name,
        }
      })
    } else if (event.currentTarget.href.startsWith('mailto:')) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'booking_email_click',
          location: booking.confirmedLocation!.name,
        }
      })
    }

    window.location.href = event.currentTarget.href
    event.preventDefault()
  }

  useEffect(() => {
    // Perform for validation
    const newErrorState = {
      firstName: undefined as string | undefined,
      lastName: undefined as string | undefined,
      email: undefined as string | undefined,
      phone: undefined as string | undefined,
    }

    if (
      state.firstName !== undefined &&
      state.firstName.length > 0 &&
      state.firstName.length < 2
    ) {
      newErrorState.firstName = 'You should provide a name with at least 2 characters in length'
    }

    if (
      state.lastName !== undefined &&
      state.lastName.length > 0 &&
      state.lastName.length < 2
    ) {
      newErrorState.lastName = 'You should provide a name with at least 2 characters in length'
    }

    if (state.email !== undefined && state.email.length > 0 && !isValidEmailAddress(state.email)) {
      newErrorState.email = 'Please enter a valid email address'
    }

    if (state.phone !== undefined && state.phone.length > 0 && !isValidPhoneNumber(state.phone)) {
      newErrorState.phone = 'Please enter a valid phone number e.g. 7123123123'
    }

    setErrorState(newErrorState)
  }, [state])

  const handleContinue = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    // Check if there is some error state to take care of
    type ErrorStateProperties = 'firstName' | 'lastName' | 'email' | 'phone'
    for (const propertyName of Object.keys(errorState)) {
      if (Object.prototype.hasOwnProperty.call(errorState, propertyName)) {
        const property = propertyName as ErrorStateProperties

        if (errorState[property] !== undefined) {
          setModalMessage({
            visible: true,
            title: 'There are a few things to fix.',
            text: 'Please check the fields and fix errors before moving forward',
            severity: 'error' as AlertColor
          })
          return
        }

        if (state[property] === undefined || (state[property]?.length === 0)) {
          setModalMessage({
            visible: true,
            title: 'Required Fields Missing',
            text: 'Please fill in all required fields marked with (*) in the form before submitting',
            severity: 'error' as AlertColor
          })
          return
        }
      }
    }

    if (
      !booking.address.address ||
      (booking.address.address.length === 0) ||
      !booking.address.postcode ||
      (booking.address.postcode.length === 0)
    ) {
      setModalMessage({
        visible: true,
        title: 'Share Your Full Address',
        text: 'In order to book your self-storage unit we will need your full address with a postcode. ' +
          'Please select your address from the list of suggestions.',
        severity: 'error' as AlertColor
      })
      return
    }

    if (booking.title === undefined || booking.duration === undefined || booking.startDate === undefined) {
      setModalMessage({
        visible: true,
        title: 'Required Fields Missing',
        text: 'Please fill in all required fields marked with (*) in the form before submitting',
        severity: 'error' as AlertColor
      })
      return
    }

    // If booking.phone starts with a zero, remove it= ''
    if (booking.phone?.startsWith('0')) {
      booking.phone = booking.phone.slice(1)
    }

    // Check terms & Privacy Policy clicks <
    if (!state.agreeToTerms || !state.agreeToPrivacyPolicy) {
      setModalMessage({
        visible: true,
        title: 'Agreement Required',
        text: 'Please agree to our Terms and Conditions and Privacy Policy before moving forward',
        severity: 'warning' as AlertColor
      })
      return
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'quote_contact_form_submitted',
        location: booking.confirmedLocation?.name
      }
    })

    navigate('/summary')
  }

  return (
    <Container {...CONTAINER}>
      <Typography variant="h1">Reservation Details</Typography>
      {booking.confirmedLocation!.contacts?.phone !== undefined &&
      <Typography
        variant="body1"
        sx={{
          mt: 1.5,
          mb: 6
        }}
      >
        Reserve this unit for free using the form below or call the team on{' '}
        <Link
          to={`tel:${booking.confirmedLocation!.contacts.phone || ''}`}
          style={{
            color: palette.primary.main,
            fontWeight: 'bold'
          }}
          onClick={handleLinkClick}
        >
          {booking.confirmedLocation!.contacts.phone || ''}
        </Link>
      </Typography>}
      <Stack
        component="form"
        spacing={{
          xs: 3,
          sm: 4,
        }}
      >
        <UnitLarge
          location={booking.confirmedLocation!}
          size={booking.confirmedSize!}
          image={false}
          nav={false}
        />
        <Box
          component={Paper}
          variant="outlined"
          {...BOX}
        >
          <ModalBox
            open={requestCallbackVisible}
            onClose={toggleRequestCallBackVisibility}
            hasOKButton={false}
          >
            <RequestCallback
              siteId={booking.confirmedLocation!.siteId}
              siteName={booking.confirmedLocation!.name}
              description={`This store only takes online reservations ${booking.confirmedLocation!.weeksInAdvanceBooking} ${booking.confirmedLocation!.weeksInAdvanceBooking === 1 ? 'week' : 'weeks'} in advance. Please leave your details below and we will be in touch regarding your requirement.`}
              reason={`Booking a storage at ${booking.confirmedLocation!.name} for a date more than ${booking.confirmedLocation!.weeksInAdvanceBooking} ${booking.confirmedLocation!.weeksInAdvanceBooking === 1 ? 'week' : 'weeks'} from today.`}
            />
          </ModalBox>
          <Typography variant="h4">Your Details</Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 1.5,
              mb: 4
            }}
            color={colors.neutral[800]}
          >
            We need your details to confirm your booking and send you a confirmation email.
            We will not add you to any mailing lists or share your details with third parties.
          </Typography>
          <Grid container {...FORM}>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Title *</InputLabel>
                <Select
                  labelId="title-label"
                  label="Title *"
                  value={state.title}
                  onChange={handleTitleChange}
                  required
                >
                  {Object.keys(TitleType)
                    .map(key => (
                      <MenuItem key={key} value={TitleType[key as keyof typeof TitleType]}>
                        {TitleType[key as keyof typeof TitleType]}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="firstName"
                name="firstName"
                label="First name"
                error={errorState.firstName !== undefined}
                helperText={errorState.firstName}
                value={state.firstName ?? ''}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="lastName"
                name="lastName"
                label="Last name"
                error={errorState.lastName !== undefined}
                helperText={errorState.lastName}
                value={state.lastName ?? ''}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <TextField
                id="email"
                name="email"
                label="Email"
                error={errorState.email !== undefined}
                helperText={errorState.email}
                type="email"
                value={state.email ?? ''}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <FormControl fullWidth>
                <InputLabel id="phoneCode-label">Country code*</InputLabel>
                <Select
                  labelId="phoneCode-label"
                  label="Country code*"
                  value={state.phoneCountryCode.code}
                  onChange={handlePhoneCodeChange}
                >
                  {countryCodes.map((countryCode: CountryCodeType) => (
                    <MenuItem key={countryCode.code} value={countryCode.code}>
                      {`${countryCode.name} (${countryCode.code})`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <TextField
                id="phone"
                name="phone"
                label="Mobile phone"
                error={errorState.phone !== undefined}
                helperText={errorState.phone}
                type="tel"
                value={state.phone ?? ''}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={addressOptions}
                getOptionLabel={(option: AutocompleteOptionProps) => option.label ?? ''}
                popupIcon={<Search color="primary"/>}
                sx={{
                  '& .MuiAutocomplete-popupIndicator': { transform: 'none' },
                }}
                onChange={handleAddressChange}
                onInputChange={debounce((value: ChangeEvent<HTMLInputElement>) => {
                  handleAddressInputChange(value)
                }, 500)}
                value={currentAddress}
                filterOptions={x => x}
                noOptionsText="No address found"
                renderInput={parameters => (
                  <TextField
                    {...parameters}
                    label="Address *"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          component={Paper}
          variant="outlined"
          {...BOX}
        >
          <Typography variant="h4">Reservation Details</Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 1.5,
              mb: 4
            }}
            color={colors.neutral[800]}
          >
            Our {booking.confirmedLocation!.name} store takes reservations up to {booking.confirmedLocation!.weeksInAdvanceBooking} {booking.confirmedLocation!.weeksInAdvanceBooking === 1 ? 'week' : 'weeks'} in advance.
            If you require a date beyond {booking.confirmedLocation!.weeksInAdvanceBooking} {booking.confirmedLocation!.weeksInAdvanceBooking === 1 ? 'week' : 'weeks'},
            please select the date and provide your details for us to contact you.
          </Typography>
          <Grid container {...FORM}>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start date *"
                  sx={{ width: '100%' }}
                  format="DD/MM/YYYY"
                  value={state.startDate instanceof Date ? dayjs(state.startDate) : null}
                  onChange={handleStartDateChange}
                  disablePast
                  disableHighlightToday
                  minDate={dayjs()
                    .add(1, 'day')}
                  slots={{
                    openPickerIcon: OpenPickerIcon
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Storage duration</InputLabel>
                <Select
                  labelId="duration-label"
                  label="Storage Duration *"
                  value={state.duration ?? ''}
                  onChange={handleDurationChange}
                  required
                >
                  {Object.keys(DurationTypeTitles)
                    .map(key => (
                      <MenuItem key={key} value={key}>
                        {DurationTypeTitles[key as keyof typeof DurationTypeTitles]}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <FormGroup sx={{ gap: 1.5 }}>
          <FormControlLabel
            required
            control={
              <Checkbox
                name="agreeToTerms"
                checked={state.agreeToTerms}
                onChange={handleInputChange}
              />
            }
            label="I agree to the Terms and Conditions of this service"
          />
          <FormControlLabel
            required
            control={
              <Checkbox
                name="agreeToPrivacyPolicy"
                checked={state.agreeToPrivacyPolicy}
                onChange={handleInputChange}
              />
            }
            label="I agree to the Privacy Policy and cookies policy of this site"
          />
        </FormGroup>
        <InfoBox iconPos="top" mt={3}>
          Please check our{' '}
          <Link to="https://www.vanguardstorage.co.uk/self-storage-terms-conditions/" target="_blank">
            Terms and Conditions
          </Link>
          {' '}and{' '}
          <Link to="https://www.vanguardstorage.co.uk/privacy-policy/" target="_blank">
            Privacy Policy
          </Link>
        </InfoBox>
      </Stack>
      <Box
        mt={{
          xs: 4,
          sm: 5,
        }}
      >
        <Button variant="contained" color="primary" endIcon={<ArrowForward/>} onClick={handleContinue} size="large" {...CTA}>
          Reserve Now
        </Button>
      </Box>
      <ModalBox
        open={modalMessage.visible}
        onClose={() => {
          setModalMessage(previousState => ({
            ...previousState,
            visible: false
          }))
        }}
      >
        <IconWithText
          title={modalMessage.title}
          text={modalMessage.text}
          severity={modalMessage.severity}
        />
      </ModalBox>
    </Container>
  )
}

export default PersonalDetails
