import { createTheme } from '@mui/material'

const fontFamily = '\'Nexa\', sans-serif'

export const rounded = {
  none: '0px',
  small: '4px',
  medium: '8px',
  large: '16px',
}

export const shadows = {
  large: '0px 8px 16px rgba(6, 18, 35, 0.1)',
  largeInverted: '0px -8px 16px rgba(6, 18, 35, 0.05)',
  largeOpaque: '0px -8px 16px rgba(6, 18, 35, 0)'
}

export const colors = {
  gold: {
    100: '#FFF2E5',
    200: '#FFDFBF',
    400: '#EEA65F',
    500: '#DE8932'
  },
  blue: {
    100: '#DBEAF5',
    300: '#8BC4EA',
    700: '#04315A',
  },
  neutral: {
    50: '#F5F7FA',
    100: '#E3E9EF',
    300: '#BFC7D1',
    200: '#CFD7E1',
    400: '#A1A9B8',
    500: '#8790A1',
    600: '#657181',
    800: '#232938',
    900: '#161928'
  },
  white: {
    25: 'rgba(255, 255, 255, 0.25)',
    50: 'rgba(255, 255, 255, 0.5)',
    75: 'rgba(255, 255, 255, 0.75)',
    100: 'rgba(255, 255, 255, 1)',
  }
}

export const palette = {
  primary: {
    main: colors.blue[700],
    light: colors.blue[300],
  },
  secondary: {
    main: colors.gold[400],
    darker: colors.gold[500],
  },
  success: {
    main: '#228B22',
  },
  info: {
    main: colors.blue[700],
  },
  warning: {
    main: colors.gold[500],
  },
  error: {
    main: '#FF5555',
  },
}

export const fontSizes = {
  body: {
    xs: {
      fontSize: '13px',
      lineHeight: '18px',
      '& strong': {
        fontWeight: 800
      }
    },
    sm: {
      fontSize: '15px',
      lineHeight: '23px',
    },
  },
  subtitle: {
    fontSize: '26px',
    lineHeight: '36px',
  },
  title: {
    fontSize: 72,
    fontWeight: 800,
    lineHeight: '80px'
  }
}

export const hoverState = {
  backgroundColor: colors.neutral[900],
  borderColor: colors.neutral[900],
  color: colors.white[100],
}

const themeRaw = createTheme({
  typography: {
    fontFamily,
  }
})

export const contentHeight = 'calc(100vh - var(--header-height))'
export const mobileHeroHeight = '35vh'

export const styles = {
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    h1: {
      color: colors.neutral[900],
      fontSize: 36,
      lineHeight: '42px',
      fontWeight: 800,
      '&.MuiTypography-gutterBottom': {
        marginBottom: themeRaw.spacing(5)
      },
      '& strong': {
        fontWeight: 900
      },
      '@media (max-width: 600px)': {
        fontSize: 28,
        lineHeight: '34px',
        '&.MuiTypography-gutterBottom': {
          marginBottom: themeRaw.spacing(3)
        }
      },
    },
    h2: {
      color: colors.neutral[900],
      fontSize: 28,
      '@media (max-width: 600px)': {
        fontSize: 24,
      },
    },
    h3: {
      color: colors.neutral[900],
      fontSize: 24,
      '@media (max-width: 600px)': {
        fontSize: 20,
      },
    },
    h4: {
      color: colors.neutral[900],
      fontWeight: 800,
      fontSize: 30,
      lineHeight: '36px',
      '&.MuiTypography-gutterBottom': {
        marginBottom: themeRaw.spacing(4)
      },
      '@media (max-width: 600px)': {
        ...fontSizes.subtitle,
        '&.MuiTypography-gutterBottom': {
          marginBottom: themeRaw.spacing(3)
        }
      }
    },
    h5: {
      color: colors.neutral[900],
      fontSize: 21,
      fontWeight: 800,
      lineHeight: '24px'
    },
    h6: {
      color: colors.neutral[900],
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '18px'
    },
    caption: {
      color: colors.neutral[800],
      fontSize: 12,
      lineHeight: '16px',
    },
    overline: {
      color: colors.neutral[900],
      fontSize: 20,
      fontWeight: 800,
      letterSpacing: 0,
      lineHeight: '29px',
      textTransform: 'none',
    },
    body1: {
      color: colors.neutral[900],
      fontFamily,
      ...fontSizes.body.sm,
      '@media (max-width: 600px)': {
        '&.MuiTypography-root:not(.static)': {
          ...fontSizes.body.xs,
        }
      }

    },
    body2: {
      color: colors.neutral[900],
      ...fontSizes.body.xs
    },
    a: {
      color: colors.gold[400],
      textDecoration: 'none',
    },
  },
  shape: {
    borderRadius: 0
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '&, & .MuiOutlinedInput-root, & .MuiInputBase-input, & .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.neutral[300],
            '&:hover fieldset': {
              borderColor: colors.neutral[900],
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.gold[400],
            }
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiSelect-icon': {
            color: colors.neutral[900],
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.neutral[300],
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.gold[400],
          },
        },
      }
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            boxShadow: shadows.large,
          },
          '& .MuiAutocomplete-listbox': {
            color: colors.neutral[900],
            '& .MuiAutocomplete-option': {
              '&:hover:not([aria-selected=true])': {
                background: colors.neutral[100],
              },
              '&[aria-selected=true]': {
                '&, &.Mui-focused': {
                  background: colors.gold[400],
                  color: colors.white[100],
                }
              }
            }
          }
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            boxShadow: shadows.large,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          fontWeight: 700,
          textTransform: 'none',
          padding: themeRaw.spacing(1.5, 3),
          ...fontSizes.body.sm,
          '&.pill': {
            borderRadius: '9999rem',
            lineHeight: 1,
            padding: `${themeRaw.spacing(0.5)} ${themeRaw.spacing(2)}`,
            '&, &:hover': {
              borderWidth: '1px',
            }
          },
          '&:hover': hoverState
        }
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            '&': {
              backgroundColor: colors.white[100],
            },
            '&, &.Mui-disabled': {
              borderColor: colors.neutral[200],
              color: colors.neutral[900]
            },
            '&.Mui-disabled': {
              opacity: 0.5
            }
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            '&.Mui-disabled': {
              backgroundColor: colors.neutral[300],
              color: colors.white[100]
            }
          },
        }
      ]
    },
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          backgroundColor: colors.gold[100],
          border: `1px solid ${colors.gold[200]}`,
          color: colors.neutral[900],
        },
        standardInfo: {
          backgroundColor: colors.blue[100],
          border: `1px solid ${colors.blue[300]}`,
          color: colors.blue[700]
        },
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colors.neutral[100]}`,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (max-width: 600px)': {
            paddingLeft: themeRaw.spacing(3),
            paddingRight: themeRaw.spacing(3),
          },
          '@media (min-width: 601px)': {
            maxWidth: '1400px',
            paddingLeft: themeRaw.spacing(5),
            paddingRight: themeRaw.spacing(5),
          }
        },
      },
    },
    MuiPaper: {
      outlined: {
        borderColor: colors.neutral[100],
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '& a': {
            color: colors.gold[400],
            textDecoration: 'none',
            '&:hover': {
              color: colors.neutral[900],
            }
          }
        }
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          '&.MuiIcon-colorDisabled': {
            color: colors.neutral[300],
          },
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: colors.neutral[900],
          fontWeight: 700,
          '& input::placeholder': {
            color: colors.neutral[500],
            fontWeight: 700,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.neutral[600],
          '&.Mui-focused': {
            color: colors.gold[400],
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.square': {
            backgroundColor: colors.neutral[50],
            border: `1px solid${colors.neutral[100]}`,
            borderRadius: 0,
            color: colors.blue[700],
            '&.MuiIconButton-colorPrimary': {
              backgroundColor: colors.blue[700],
              borderColor: colors.blue[700],
              color: colors.white[100]
            },
            '&.gold': {
              borderColor: colors.gold[500],
              backgroundColor: colors.gold[400],
              color: colors.neutral[900],
            }
          }
        }
      }
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          '&, .MuiButtonBase-root': {
            color: colors.neutral[900],
            '&.MuiButtonBase-root.MuiPickersDay-root.Mui-disabled:not(.Mui-selected)': {
              color: colors.neutral[300],
            }
          },
          '.MuiDayCalendar-weekDayLabel': {
            color: colors.neutral[600],
          },
          '.MuiPickersYear-yearButton': {
            '&:hover': {
              backgroundColor: colors.neutral[100],
            },
            '&.Mui-selected': {
              '&, &:focus, &:hover': {
                backgroundColor: colors.gold[400],
              }
            }
          },
          '.MuiButtonBase-root.MuiPickersDay-root': {
            '&:not(.Mui-selected)': {
              backgroundColor: 'transparent',
              borderColor: colors.gold[400],
              '&:hover': {
                backgroundColor: colors.neutral[100],
              }
            },
            '&.Mui-selected': {
              backgroundColor: colors.gold[400],
            }
          },
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colors.blue[700],
          '&:hover:not(.Mui-selected), &.Mui-focusVisible': {
            backgroundColor: colors.neutral[100],
          },
          '&.Mui-selected': {
            '&, &:hover': {
              backgroundColor: colors.gold[400],
              color: colors.white[100]
            }
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderWeight: '1px',
          color: colors.neutral[300],
          paddingBottom: 0,
          paddingTop: 0,
          '&.Mui-checked': {
            color: colors.blue[700],
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        filledSecondary: {
          backgroundColor: colors.gold[100],
          border: `1px solid ${colors.gold[200]}`,
          color: colors.neutral[900]
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        markLabel: {
          color: colors.neutral[600],
          fontSize: fontSizes.body.sm.fontSize,
          fontWeight: 700,
          '&.MuiSlider-markLabelActive': {
            color: colors.neutral[900],
            fontWeight: 800,
          }
        },
        markLabelActive: {
          color: colors.neutral[900],
        },
        mark: {
          borderRadius: '50%',
          height: '6px',
          transform: 'translate(-3px, -50%)',
          width: '6px'
        },
        rail: {
          backgroundColor: colors.neutral[100],
          opacity: 1
        },
        thumb: {
          color: colors.gold[500]
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: fontSizes.body.sm.fontSize,
          fontWeight: 400,
          lineHeight: '18px',
          '& a, &.Mui-completed a': {
            color: colors.white[75],
            textDecoration: 'none'
          },
          '&.Mui-active a, &.Mui-completed a:hover': {
            color: colors.white[100],
          },
          '&:not(.Mui-completed)': {
            pointerEvents: 'none',
          }
        },
        iconContainer: {
          '&:not(.MuiStepLabel-alternativeLabel)': {
            paddingRight: 0,
            marginRight: themeRaw.spacing(1),
          },
          '&:not(.Mui-active):not(.Mui-completed)': {
            borderRadius: '50%',
            boxShadow: `0 0 0 1px ${colors.white[50]} inset`,
            svg: {
              circle: {
                display: 'none',
              },
              text: {
                fill: colors.white[75]
              }
            }
          },
          '&.Mui-completed svg path': {
            color: colors.white[100],
          },
          '&.Mui-active svg': {
            circle: {
              color: colors.white[100],
            },
            text: {
              fill: colors.blue[700],
            }
          }
        },
      },
    },
  },
  shadows: [
    'none',
    '0px 2px 4px rgba(33, 63, 109, 0.05)',
    '0px 4px 8px rgba(33, 63, 109, 0.05)',
    shadows.large,
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
}

export const theme = createTheme(themeRaw, styles)
