import React, {
  useContext, useState, useEffect, type MouseEvent
} from 'react'
import {
  Container, Box, Paper, Typography, Grid, Button, type AlertColor
} from '@mui/material'
import {
  PaymentElement, useStripe, useElements, Elements
} from '@stripe/react-stripe-js'
import {
  loadStripe, type Stripe
} from '@stripe/stripe-js'
import InfoBox from '../../components/InfoBox'
import { type BookingContextProps } from '../../utils/data'
import {
  callSMProxyApiCreatePaymentIntent, type SMProxyCreatePaymentIntentDataProps, type SMProxyResponseProps
} from '../../utils/apis'
import BookingContext from '../../components/BookingContext'
import Loading from '../../components/Loading'
import IconWithText from '../../components/IconWithText'
import {
  BOX, CONTAINER, CTA
} from '../../utils/commonStyling'

const PaymentForm = () => {
  const booking: BookingContextProps = useContext(BookingContext)

  const stripe = useStripe()
  const elements = useElements()

  const [
    message,
    setMessage,
  ] = useState({
    active: false,
    severity: 'error' as AlertColor,
    title: 'Message title',
    text: 'Message sample text'
  })

  const [
    buttonDisabled,
    setButtonDisabled,
  ] = useState<boolean>(true)

  const handleLoadError = (error: unknown) => {
    console.error('Failed to load payment element', error)
    setMessage({
      active: true,
      severity: 'error',
      title: 'Payment Gateway Initialization Failed',
      text: 'We have failed to initialize our payment gateway. Please try again'
    })
  }

  const handleReady = () => {
    setButtonDisabled(false)
  }

  const handlePayment = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if (!stripe || !elements) {
      setMessage({
        active: true,
        severity: 'error',
        title: 'Payment Gateway Not Yet Initialized',
        text: 'Our payment Gateway has not initialized yet. Please try again!'
      })
      return
    }

    setButtonDisabled(true)

    localStorage.setItem('booking', JSON.stringify(booking))

    stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/booking`,
      },
    })
      .then(() => {
        setButtonDisabled(false)
      })
      .catch(error => {
        console.error(error)
        setMessage({
          active: true,
          severity: 'error',
          title: 'Payment Authorisation Failed',
          text: 'We have failed to authorise your payment. Please try again'
        })
        setButtonDisabled(false)
      })
  }

  return message.active ?
    <IconWithText
      title={message.title}
      text={message.text}
      severity={message.severity}
    /> :
    <>
      <PaymentElement onLoadError={handleLoadError} onReady={handleReady}/>
      <Box mt={4}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          disabled={buttonDisabled}
          onClick={handlePayment}
          {...CTA}
        >
          Authorise &pound;{booking.confirmedSize?.discounted_week_rate?.toFixed(2) ?? booking.confirmedSize?.standard_week_rate?.toFixed(2)} Deposit
        </Button>
      </Box>
    </>
}

const PaymentDetails = () => {
  const booking: BookingContextProps = useContext(BookingContext)

  const [
    clientSecret,
    setClientSecret
  ] = useState<string | undefined>()

  const [
    stripePromise,
    setStripePromise
  ] = useState<Stripe | undefined>()

  const [
    loading,
    setLoading,
  ] = useState(true)

  const [
    message,
    setMessage,
  ] = useState({
    active: false,
    severity: 'error' as AlertColor,
    title: 'Message title',
    text: 'Message sample text'
  })

  useEffect(() => {
    if (
      booking.confirmedSize === undefined ||
      (
        booking.confirmedSize.discounted_week_rate === undefined &&
        booking.confirmedSize.standard_week_rate === undefined
      )
    ) {
      setMessage({
        active: true,
        severity: 'warning',
        title: 'Invalid Unit Size',
        text: 'No selected unit size found. Please go back and select an unit'
      })
      setLoading(false)
      return
    }

    const amount = booking.confirmedSize?.discounted_week_rate ?? booking.confirmedSize?.standard_week_rate
    if (amount === undefined) {
      setMessage({
        active: true,
        severity: 'warning',
        title: 'Invalid Unit Size',
        text: 'No selected unit size found. Please go back and select an unit'
      })
      setLoading(false)
      return
    }

    callSMProxyApiCreatePaymentIntent(amount)
      .then((response: SMProxyResponseProps<SMProxyCreatePaymentIntentDataProps>) => {
        if (response.error) {
          console.error(response.error)
          setMessage({
            active: true,
            severity: 'error',
            title: 'Payment Intent Failed',
            text: 'We have failed to create a payment intent. Please go back and try again'
          })
          setLoading(false)
          return
        }

        if (response.data === undefined || response.data.client_secret === undefined) {
          console.error('Invalid response. No data or client secret found', response)
          setMessage({
            active: true,
            severity: 'error',
            title: 'Payment Intent Failed',
            text: 'We have failed to create a payment intent. Please go back and try again'
          })
          setLoading(false)
          return
        }

        setClientSecret(response.data.client_secret)
        setLoading(false)

        try {
          loadStripe(process.env.REACT_APP_STRIPE_API_TOKEN!)
            .then((stripePromise: Stripe) => {
              setStripePromise(stripePromise)
            })
            .catch(error => {
              console.error(error)
              setMessage({
                active: true,
                severity: 'error',
                title: 'Payment Gateway Initialization Failed',
                text: 'We have failed to initialize our payment gateway. Please try again'
              })
            })
        } catch (error) {
          console.error(error)
          setMessage({
            active: true,
            severity: 'error',
            title: 'Payment Gateway Initialization Failed',
            text: 'We have failed to initialize our payment gateway. Please try again'
          })
        }
      })
      .catch(error => {
        console.error(error)
        setMessage({
          active: true,
          severity: 'error',
          title: 'Payment Intent Failed',
          text: 'We have failed to create a payment intent. Please go back and try again'
        })
        setLoading(false)
      })
  }, [
    booking.confirmedSize,
    booking.confirmedSize?.standard_week_rate,
    booking.confirmedSize?.discounted_week_rate
  ])

  return (
    <Container {...CONTAINER}>
      <Typography variant="h1" gutterBottom>Payment Details</Typography>
      <Paper
        component="form"
        variant="outlined"
        {...BOX}
      >
        {loading && <Loading/>}
        {message.active &&
          <IconWithText
            title={message.title}
            text={message.text}
            severity={message.severity}
          />}
        {clientSecret && !message.active &&
          <>
            <Typography
              variant="h4"
              gutterBottom
            >
              Card details
            </Typography>
            <InfoBox mb={3}>
              We would need an
              { ' ' }
              <strong>authorisation of &pound;
                {booking.confirmedSize?.discounted_week_rate?.toFixed(2) ?? booking.confirmedSize?.standard_week_rate?.toFixed(2)}
              </strong> for the first week to book your self-storage.
            </InfoBox>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Elements stripe={stripePromise ?? null} options={{ clientSecret }}>
                  <PaymentForm/>
                </Elements>
              </Grid>
            </Grid>
          </>}
      </Paper>
    </Container>
  )
}

export default PaymentDetails
