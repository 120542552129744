import axios, {
  type AxiosRequestConfig, type Method, type AxiosResponse, type AxiosError
} from 'axios'
import {
  type SiteProps, type GPSCoordinates, type AutocompleteOptionProps, type AddressProps
} from './data'

export type SMProxyResponseProps<D> = {
  data: D | undefined;
  error: string | undefined;
}

export type SMProxyFindSitesDataProps = {
  sites: SiteProps[];
  location_coordinates: GPSCoordinates;
}

export type SMProxyCreatePaymentIntentDataProps = {
  client_secret: string;
}

export type SMProxyCreateCustomerWithReservationResponseDataProps = {
  reservation_id: string;
  customer_id: string;
  invoice_id: string;
  payment_id: string;
}

export type SMProxyCustomerWithReservationData = {
  site_id: string;
  title: string | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
  address_line_1: string | undefined;
  town: string | undefined;
  county: string | undefined;
  postcode: string | undefined;
  move_in_date: Date | string | undefined;
  opt_in: boolean;
  email: string | undefined;
  phone_number: string | undefined;
  size: string | undefined;
  size_code_id: string | undefined;
  deposit_amount: number | undefined;
  stripe_payment_ref: string | undefined;
}

export type SMProxyConfirmReservationDataProps = SMProxyCustomerWithReservationData & {
  token: string | undefined;
}

export type SMProxyCreateCustomerWithReservationDataProps = SMProxyCustomerWithReservationData & {
  site_photo_url: string | undefined;
  unit_size: number | undefined;
  duration: string | undefined;
  price_per_week: number | undefined;
  special_price_per_week: number | undefined;
  location_search_text: string | undefined;
}

export type SMProxyRequestCallbackProps = {
  site_id: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  name: string | undefined;
  token: string | undefined;
  reason: string | undefined;
}

export type SMProxyRequestCallbackResponseProps = {
  success: boolean;
  message: string;
}

export type SMProxyConfirmReservationResponseProps = {
  success: boolean;
  message: string;
}

function formatDateToYYYYMMDD(date: Date): string {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1)
    .padStart(2, '0') // Month is 0-based, so we add 1 and pad with '0'
  const day = String(date.getDate())
    .padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const callSMProxyApiFindSites = async (
  searchValue: string
): Promise<SMProxyResponseProps<SMProxyFindSitesDataProps>> => callSMProxyAPI<SMProxyFindSitesDataProps>('GET', `/sites/find/${searchValue}`)

export const callSMProxyApiCreatePaymentIntent = async (
  amount: number
): Promise<SMProxyResponseProps<SMProxyCreatePaymentIntentDataProps>> => callSMProxyAPI<SMProxyCreatePaymentIntentDataProps>(
  'POST',
  '/createPaymentIntent',
  {
    amount
  }
)

export const callSMProxyApiRequestCallback = async (
  payload: SMProxyRequestCallbackProps
): Promise<SMProxyResponseProps<SMProxyRequestCallbackResponseProps>> => callSMProxyAPI<SMProxyRequestCallbackResponseProps>(
  'POST',
  '/requestCallback',
  payload
)

export const callSMProxyApiConfirmReservation = async (
  payload: SMProxyConfirmReservationDataProps
): Promise<SMProxyResponseProps<SMProxyConfirmReservationResponseProps>> => {
  // Transform the date to a string to normalize it for the API
  if (payload.move_in_date !== undefined && payload.move_in_date instanceof Date) {
    payload.move_in_date = formatDateToYYYYMMDD(payload.move_in_date)
  }

  return callSMProxyAPI<SMProxyConfirmReservationResponseProps>(
    'POST',
    '/confirmReservation',
    payload
  )
}

export const callSMProxyApiCreateCustomerWithReservation = async (
  siteId: string,
  payload: SMProxyCreateCustomerWithReservationDataProps
): Promise<SMProxyResponseProps<SMProxyCreateCustomerWithReservationResponseDataProps>> => {
  // Transform the date to a string to normalize it for the API
  if (payload.move_in_date !== undefined && payload.move_in_date instanceof Date) {
    payload.move_in_date = formatDateToYYYYMMDD(payload.move_in_date)
  }

  // Send the API request and return the promise
  return callSMProxyAPI<SMProxyCreateCustomerWithReservationResponseDataProps>(
    'POST',
    `/site/${siteId}/customerWithReservation`,
    payload
  )
}

export const callSMProxyAPI = async <D> (
  method: Method,
  endpoint: string,
  data: Record<string, unknown> | undefined = undefined
): Promise<SMProxyResponseProps<D>> => {
  const config: AxiosRequestConfig = {
    url: encodeURI(endpoint),
    baseURL: process.env.REACT_APP_SM_PROXY_API_HOST,
    method,
    data
  }
  try {
    const response: AxiosResponse<SMProxyResponseProps<D>> = await axios(config)
    return {
      data: response.data as D,
      error: undefined
    }
  } catch (error: unknown) {
    return {
      data: undefined,
      error: ((error as AxiosError).response?.data as { detail: string }).detail as string | undefined
    }
  }
}

type getAddressAutocompleteResponseProps = {
  data: {
    suggestions: Array<{
      id: string;
      address: string;
      url: string;
    }>;
  };
}

type getLocationAutocompleteResponseProps = {
  data: {
    suggestions: Array<{
      id: string;
      location: string;
      url: string;
    }>;
  };
}

type getAddressDetailsResponseProps = {
  data: {
    latitude: number;
    longitude: number;
    line_1: string;
    line_2: string;
    line_3: string;
    postcode: string;
    building_name: string;
    building_number: string;
    thoroughfare: string;
    town_or_city: string;
    district: string;
    county: string;
    formatted_address: string[];
  };
}

export const fetchAddressAutocompleteSuggestions = async (searchValue: string): Promise<AutocompleteOptionProps[]> => {
  let suggestions: AutocompleteOptionProps[] = []
  await axios
    .get(`https://api.getAddress.io/autocomplete/${searchValue}?all=true&api-key=${process.env.REACT_APP_GET_ADDRESS_API_KEY!}`)
    .then((response: getAddressAutocompleteResponseProps) => {
      if (!response.data.suggestions) {
        return
      }

      suggestions = response.data.suggestions.map(suggestion => ({
        id: suggestion.id,
        label: suggestion.address
      }))
    })
    .catch(() => {
      console.error('Failed to get address suggestions')
    })
  return suggestions
}

export const fetchLocationAutocompleteSuggestions = async (searchValue: string): Promise<AutocompleteOptionProps[]> => {
  let suggestions: AutocompleteOptionProps[] = []
  await axios
    .get(`https://api.getAddress.io/location/${searchValue}?&api-key=${process.env.REACT_APP_GET_ADDRESS_API_KEY!}`)
    .then((response: getLocationAutocompleteResponseProps) => {
      if (!response.data.suggestions) {
        return
      }

      suggestions = response.data.suggestions.map(suggestion => ({
        id: suggestion.id,
        label: suggestion.location
      }))
    })
    .catch(() => {
      console.error('Failed to get location suggestions')
    })
  return suggestions
}

export const fetchAddressDetails = async (addressId: string): Promise<AddressProps> => {
  let address_props: AddressProps = {
    id: undefined,
    address: undefined,
    town: undefined,
    county: undefined,
    postcode: undefined,
    fullAddress: undefined
  }
  await axios
    .get(`https://api.getAddress.io/get/${addressId}?api-key=${process.env.REACT_APP_GET_ADDRESS_API_KEY!}`)
    .then((response: getAddressDetailsResponseProps) => {
      const address = response.data
      address_props = {
        id: addressId,
        address: (address.line_1 + ' ' + address.line_2 + ' ' + address.line_3).trim(),
        town: address.town_or_city,
        county: address.county,
        postcode: address.postcode,
        fullAddress: address.formatted_address.filter(item => item !== '')
          .join(', ')
      }
    })
    .catch(() => {
      console.error('Failed to get address details')
    })
  return address_props
}
