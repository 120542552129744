import React, { useState } from 'react'
import {
  Box, Button, Paper, Typography, Chip, Grid
} from '@mui/material'
import {
  ArrowForward, LocalShipping,
  PhoneInTalk, ZoomOutMap
} from '@mui/icons-material'
import {
  type LocationProps, type SizeProps
} from '../utils/data'
import {
  colors, theme,
} from '../styles'
import MapBox from './Map'
import RequestCallback from './RequestCallback'
import ModalBox from './ModalBox'
import InfoBox from './InfoBox'

type UnitLargeProps = {
  location: LocationProps;
  size: SizeProps;
  image?: boolean;
  nav?: boolean;
  select?: () => void;
}

const UnitLarge = ({
  location, size, image = true, nav = true, select
}: UnitLargeProps) => {
  const [
    requestCallbackVisible,
    setRequestCallbackVisible
  ] = useState(false)

  const toggleRequestCallBackVisibility = () => {
    setRequestCallbackVisible(!requestCallbackVisible)
  }

  // Get the location size from the selected size - we need this because we need prices & other information
  const location_size = location.sizes.find((item: SizeProps) => item.size === size.size)

  if (!location_size) {
    return (
      <InfoBox>
        Sorry, we do not have <strong>{size.size} ft<sup>2</sup></strong> space available at <strong>{location.name}</strong>. Please review other available options.
      </InfoBox>
    )
  }

  size = location_size

  return (
    <Paper
      component={Box}
      variant="outlined"
      display="flex"
      flexDirection={{
        xs: 'column',
        md: 'row'
      }}
      sx={{
        borderColor: colors.neutral[100]
      }}
      position="relative"
    >
      <MapBox
        center={location.coordinates}
        centerPin={location}
        zoom={14}
        offset={0.0005}
        controls
        height={{
          xs: theme.spacing(17),
          sm: theme.spacing(23),
          md: 'auto'
        }}
        width={{
          xs: '100%',
          md: theme.spacing(45),
        }}
      />
      <Box
        p={3}
        display="flex"
        width="100%"
        flexDirection={{
          xs: 'column-reverse',
          sm: 'row'
        }}
        boxSizing="border-box"
        gap={3}
      >
        <Box flexGrow="1">
          <Box display="flex" alignItems="center" gap={1} mb={1}>
            <ZoomOutMap
              fontSize="small"
            />
            <Typography variant="overline" fontWeight={700} noWrap>{size.size} ft<sup>2</sup></Typography>
          </Box>
          <Typography
            variant="h4"
          >
            {location.name}
          </Typography>
          <Typography
            variant="caption"
          >
            {location.address}
          </Typography>
          <Box mt={3}>
            <Grid
              container
              columnSpacing={5}
              rowSpacing={2}
            >
              {size.standard_week_rate &&
                <>
                  <Grid item xs={6} lg={4}>
                    <Typography
                      variant="overline"
                      {...size.discounted_week_rate && {
                        sx: {
                          textDecoration: 'line-through',
                        }
                      }}
                      noWrap
                      component="div"
                    >
                      &pound;{size.standard_week_rate.toFixed(2)}
                    </Typography>
                    <Typography
                      variant="caption"
                      color={colors.neutral[600]}
                      component="div"
                    >
                      Price per week (inc. VAT)
                    </Typography>
                  </Grid>
                  {size.discounted_week_rate &&
                    <Grid item xs={6} lg={4}>
                      <Typography
                        variant="overline"
                        noWrap
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          gap: 1
                        }}
                      >
                        <div>&pound;{size.discounted_week_rate.toFixed(2)}</div>
                        <Chip label="Sale" color="secondary" size="small"/>
                      </Typography>
                      <Typography
                        variant="caption"
                        color={colors.neutral[600]}
                        component="div"
                      >
                        Price per week for the first 8 weeks (inc. VAT)
                      </Typography>
                    </Grid>}
                </>}
              {location.manAndVan &&
                <Grid item xs={12} lg={4}>
                  <Typography
                    variant="overline"
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: 1
                    }}
                  >
                    <LocalShipping
                      color="secondary"
                      fontSize="small"
                    />
                    <div>Free Man &amp; Van</div>
                  </Typography>
                  <Typography
                    color={colors.neutral[600]}
                    variant="caption"
                    component="div"
                  >
                    T&amp;C’s apply to the “Free Man and Van” service
                  </Typography>
                </Grid>}
            </Grid>
          </Box>
          {nav &&
          <Box
            display="flex"
            gap={{
              xs: 1,
              xl: 2
            }}
            flexDirection={{
              xs: 'column',
              sm: 'row'
            }}
            flexWrap="wrap"
            mt={3}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={select}
              endIcon={<ArrowForward/>}
              sx={{
                flex: 1
              }}
            >
              Reserve for free
            </Button>
            <Button
              color="primary"
              variant="outlined"
              endIcon={<PhoneInTalk/>}
              onClick={toggleRequestCallBackVisibility}
              sx={{
                flex: 1
              }}
            >
              Request a callback
            </Button>
            <ModalBox
              open={requestCallbackVisible}
              onClose={toggleRequestCallBackVisibility}
              hasOKButton={false}
            >
              <RequestCallback
                siteId={location.siteId}
                siteName={location.name}
                description={`Please leave your details and we will get back to you with a quote for ${size.physical_size} sq. ft. space at ${location.name}.`}
                reason={`Storage unit ${size.physical_size} sq. ft. at ${location.name}`}
              />
            </ModalBox>
          </Box>}
        </Box>
        {location.image && image &&
          <Box
            display={{
              xs: 'none',
              lg: 'block',
            }}
            height="100%"
            minWidth={{
              md: 240,
              lg: 320
            }}
            width={{
              md: 240,
              lg: 320
            }}
          >
            <img
              src={`${location.image}?w=248&fit=crop&auto=format`}
              srcSet={`${location.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={location.name}
              loading="lazy"
              style={{
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              height="100%"
              width="100%"
            />
          </Box>}
      </Box>
    </Paper>
  )
}

export default UnitLarge
