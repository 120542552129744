import { theme } from '../styles'

export const CTA = {
  sx: {
    minWidth: theme.spacing(32),
    width: {
      xs: '100%',
      sm: 'auto'
    }
  }
}
export const BOX = {
  sx: {
    p: {
      xs: 3,
      md: 5
    }
  }
}

export const CONTAINER = {
  sx: {
    py: {
      xs: 5,
      sm: 7
    }
  }
}

export const FORM = {
  spacing: {
    xs: 1.5,
    md: 2.5
  }
}

export const LISTER = {
  sx: {
    display: 'flex',
    flexDirection: 'column',
    gap: {
      xs: 3,
      md: 6
    }
  }
}

export const textWithIcon = {
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(1),
  textDecoration: 'none'
}
