import React from 'react'
import { Link } from 'react-router-dom'
import {
  AppBar,
  Box,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  Home, ArrowBack,
} from '@mui/icons-material'
import { colors } from '../styles'
import { textWithIcon } from '../utils/commonStyling'
import { type RouteChildrenProps } from '../App'
import { stripQueryParameters } from '../utils/helpers'
import Logo from './Logo'

type HeaderProps = {
  innerRef?: React.RefObject<HTMLDivElement>;
  routes?: RouteChildrenProps[];
  currentStep?: RouteChildrenProps;
}

const Header = ({
  innerRef,
  routes,
  currentStep,
}: HeaderProps) => {
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const availableSteps: RouteChildrenProps[] | undefined = routes?.reduce<RouteChildrenProps[]>((steps, route) => {
    const stepLabels = steps.map(step => step.step)
    if (route.step && !stepLabels.includes(route.step)) {
      steps.push(route)
    }

    return steps
  }, [])
  const activeStep = availableSteps?.findIndex(step => step.step === currentStep?.step)
  const currentPathIndex = routes?.findIndex(route => currentStep?.path === route.path)
  const previousPath: string = routes && currentPathIndex ? stripQueryParameters(routes[currentPathIndex - 1].path) : '/'
  const startPath = activeStep === 0
  const mobileStepperHeight = theme.spacing(5)

  return (
    <AppBar
      position="fixed"
      ref={innerRef}
      sx={{
        zIndex: 10,
      }}
    >
      <Toolbar>
        <Box display="flex" alignItems="center" width="100%" height={theme.spacing(7)}>
          <Box
            flex={{
              xs: 1,
              md: 0,
              lg: 1,
            }}
            display="flex"
            alignItems="center"
          >
            <Logo
              color={colors.white[100]}
            />
          </Box>
          {availableSteps &&
            <Box
              flex={lgUp ? 0 : 1}
              alignItems="center"
              justifyContent="center"
              display={{
                xs: 'none',
                md: 'flex',
              }}
              px={3}
            >
              <Stepper
                activeStep={activeStep}
                connector={null}
                sx={{
                  gap: {
                    xs: '1vw',
                    lg: 3,
                  }
                }}
                className="inverted"
              >
                {availableSteps.map(step => (
                  <Step key={step.step}>
                    <StepLabel
                      {...lgUp && {
                        sx: {
                          whiteSpace: 'nowrap'
                        }
                      }}
                    >
                      <Link to={stripQueryParameters(step.path)}>{step.step}</Link>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>}
          <Box flex={lgUp ? 1 : 0} alignItems="center" justifyContent="end" display="flex">
            <Link
              to="https://www.vanguardstorage.co.uk/"
              style={{
                color: colors.gold[400],
                ...textWithIcon
              }}
            >
              <Home fontSize="small"/>
              <Typography
                variant="body2"
                color="inherit"
                fontWeight={700}
                whiteSpace={{
                  xs: 'nowrap',
                  md: 'normal',
                }}
              >
                Vanguard Home
              </Typography>
            </Link>
          </Box>
        </Box>
      </Toolbar>
      {availableSteps &&
        <Box
          alignItems="center"
          borderTop={`1px solid ${colors.white[25]}`}
          component="nav"
          display={{
            xs: 'flex',
            md: 'none',
          }}
          gap={2}
          height={mobileStepperHeight}
          width="100%"
        >
          <Link
            to={previousPath}
            style={{
              ...startPath && { pointerEvents: 'none' }
            }}
          >
            <IconButton
              size="small"
              className="square"
              sx={{
                height: mobileStepperHeight,
                width: mobileStepperHeight,
                '&.square': {
                  background: 'none',
                  borderColor: 'transparent',
                  boxShadow: `1px 0 0 ${colors.white[25]}`,
                  color: colors.white[100],
                  '&:hover': {
                    backgroundColor: colors.neutral[900],
                  },
                  '&.Mui-disabled': {
                    color: colors.white[25]
                  }
                }
              }}
              disabled={startPath}
            >
              <ArrowBack/>
            </IconButton>
          </Link>
          {activeStep !== undefined &&
            <Typography variant="body2" color="inherit">
              Step {activeStep + 1}/5: <strong>{availableSteps[activeStep].step}</strong>
            </Typography>}
        </Box>}
    </AppBar>
  )
}

export default Header
