import React from 'react'
import {
  type BookingContextProps, defaultSize, defaultCountryCode
} from '../utils/data'

const contextData: BookingContextProps = {
  searchText: undefined,
  searchTextGPSCoordinates: undefined,
  foundLocations: undefined,
  selectedLocation: undefined,
  selectedSize: defaultSize,
  confirmedLocation: undefined,
  confirmedSize: undefined,
  title: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  phoneCountryCode: defaultCountryCode,
  phone: undefined,
  address: {
    id: undefined,
    address: undefined,
    town: undefined,
    county: undefined,
    postcode: undefined,
    fullAddress: undefined,
  },
  startDate: undefined,
  duration: undefined,
  stripeRef: undefined,
  bookingRef: undefined
}

const BookingContext = React.createContext(contextData)

export default BookingContext
